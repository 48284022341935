<template>
    <!-- 注册页 -->
    <div class="main">
        <div class="register">
            <img src="@/assets/view-imgs/login_logo.png" />
            <el-input v-model.trim="form.legal_person" prefix-icon="el-icon-user" placeholder="法人姓名"> </el-input>
            <!-- <el-input v-model.trim="form.nickname" prefix-icon="el-icon-user-solid" placeholder="昵称"> </el-input> -->
            <el-input v-model.trim="form.account" prefix-icon="el-icon-s-custom" placeholder="账号"> </el-input>
            <el-select v-model="form.role_id" placeholder="请选择角色">
                <el-option v-for="el in jsList" :key="el.id" :label="el.title" :value="el.id"> </el-option>
            </el-select>
            <el-input v-model.trim="form.password" prefix-icon="el-icon-unlock" type="password" placeholder="密码" show-password>
            </el-input>
            <el-input v-model.trim="qrpassword" prefix-icon="el-icon-lock" type="password" placeholder="确认密码" show-password>
            </el-input>
            <button @click="register">提交注册</button>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import QueryString from "qs";
export default {
    data() {
        return {
            form: {
                legal_person: "",
                // nickname: '',
                account: "",
                password: "",
                role_id: null,
            },
            qrpassword: "",
            // 角色列表
            jsList: [],
        };
    },
    async created() {
        this.jsList = await this.getData_js();
    },
    methods: {
        // 获取所有角色
        async getData_js() {
            let { data } = await axios.get("/api/user_role_menu/rshow");
            return data;
        },
        async register() {
            if (this.form.password !== this.qrpassword) return this.$message.error("密码不一致！");
            let { data } = await axios.post("/api/logon/register", QueryString.stringify(this.form));
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
                this.$router.push("/home/vyHomePage");
            }
        },
    },
};
</script>

<style lang="less" scoped>
.main {
    width: 100vw;
    height: 100vh;
    background-color: #f2edf3;
    overflow: hidden;
    .register {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 400px;
        margin: 0 auto;
        padding: 20px;
        background-color: white;
        border-radius: 20px;
        height: 80vh;
        margin-top: 10vh;
        box-sizing: border-box;
        /deep/.el-input__inner {
            border-radius: 10px;
            margin: 5px 0;
        }
        /deep/.el-select {
            width: 100%;
        }
        & > img {
            width: 70%;
        }
        button {
            width: 100%;
            border-radius: 10px;
            border: none;
            background: linear-gradient(to right, #da8cff, #9a55ff);
            color: white;
            height: 40px;
            margin: 5px 0;
            cursor: pointer;
        }
    }
}
</style>
